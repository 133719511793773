import React, { useContext } from 'react';

import { SearchOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

import { SELECTOR_ITEM_TYPES } from './constants';
import { SidebarContext } from './SidebarContext';
import SidebarSelectorDropdownFilterButton from './SidebarSelectorDropdownFilterButton';
import { useAppSelector } from '../../../store/hook';
import InputDebounced from '../../lib/InputDebounced';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 15px 10px;
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInput = styled(InputDebounced)`
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SidebarSelectorDropdownControls = () => {
  const { setFilterKeyword } = useContext(SidebarContext);
  const { managementCompanyList, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);

  const companiesCount = managementCompanyList.length;
  const comunitiesCount = communityList.length;
  const competitorsCount = communityCompetitorsList.length;

  const handleChange = (e: any) => {
    setFilterKeyword(e?.target?.value);
  };

  return (
    <Container>
      <Search>
        <StyledInput
          placeholder={'Search communities or competitors'}
          prefix={<SearchOutlined />}
          onChange={handleChange}
        />
      </Search>
      <Filters>
        <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.ALL} />
        <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.COMMUNITY} count={comunitiesCount} />
        <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY} count={companiesCount} />
        <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.COMPETITOR} count={competitorsCount} />
      </Filters>
    </Container>
  );
};
export default SidebarSelectorDropdownControls;
