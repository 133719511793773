import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { DeleteFilled } from '@ant-design/icons/lib';
import { Button, Popconfirm, Tooltip } from 'antd';
import Table from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { useAccommodationContext } from './AccommodationContext';
import { LivingInfoDataType } from './constants';
import { mapCommunityLivingInfoToData } from './utils';
import { deleteLivingInfo, getLivingInfo } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { GLOBAL_DATE_FORMAT, MEDIA_BREAK_POINTS, STATUS } from '../../../constants';
import { OccupancyType } from '../Occupancies/constants';

interface Props {
  livingType: number;
}

const Container = styled.div`
  width: 100%;
  padding: 0 50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LivingInfoHistory = ({ livingType }: Props) => {
  const { showMessage } = useRootContext();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;
  const missingDeletionPermission = permissions?.indexOf('PAScreens.delete_accommodation') == -1;

  const { communityId } = useAccommodationContext();

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  const {
    data: accommodationData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['accommodationData', 'historical', livingType, communityId],
    queryFn: () => getLivingInfo(communityId as number, { living_info_type: livingType, active: 0 }),
    select: (data: any[]) => {
      const formatted_data: LivingInfoDataType[] = data?.map((item: any) => mapCommunityLivingInfoToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch historical Accommodation Data');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const handleDelete = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);
    deleteLivingInfo(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Historical Accommodation deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Historical Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const getTooltipContent = (record: any) => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const columns = [
    {
      title: 'Accommodation',
      dataIndex: 'apartmentType',
      render: (value: string, record: LivingInfoDataType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Square Footage',
      dataIndex: 'squareFootage',
      render: (value: string, record: LivingInfoDataType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'baseRent',
      render: (value: number, record: LivingInfoDataType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (value: string, record: LivingInfoDataType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      render: (value: Dayjs, record: LivingInfoDataType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value && value.format(GLOBAL_DATE_FORMAT)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      hidden: missingDeletionPermission,
      render: (_: any, record: Partial<OccupancyType>) => {
        return (
          <ButtonContainer>
            <Popconfirm
              title="Are you sure you want to delete this record?"
              placement="left"
              onConfirm={() => handleDelete(record)}
            >
              <Button size="small" type="link" danger icon={<DeleteFilled />} />
            </Popconfirm>
          </ButtonContainer>
        );
      },
    },
  ];

  return (
    <Container>
      <Table
        bordered
        loading={dataLoading === STATUS.LOADING || isLoading}
        scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
        columns={columns}
        dataSource={accommodationData ?? []}
      />
    </Container>
  );
};

export default LivingInfoHistory;
