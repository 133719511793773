import { useQuery } from 'react-query';

import { getCareFees, getFeesIncentives, getLivingInfo, getLivingInfoGrouped, getOccupancies } from 'apis/CommunityAPI';
import { fetchData } from 'apis/DataAPI';
import { WIDGET_TYPES } from 'common/Widgets/constants';
import { DATA_RANGE_TYPE } from 'common/Widgets/Widget/constants';
import { mapCommunityLivingInfoToData } from 'pages/price_healing/Accommodation/utils';
import { mapCommunityOccupancyResponseToData } from 'pages/price_healing/Occupancies/utils';

import { mapLivingInfoByApartmentTypeGroupInbound } from './utils';
import { mapCareFeeResponseToData, mapCommunityFeeResponseToData } from '../../pages/price_healing/Fees/utils';

export const useFetchCMASummaryData = (community: any, competitors: any[]) => {
  const { data: ratings, isLoading: ratingsLoading } = useQuery({
    queryKey: ['CMASummary', 'ratings', community?.id],
    queryFn: () => {
      const params: any = {};
      return fetchData(community?.id, WIDGET_TYPES.RATING_GRAPH, DATA_RANGE_TYPE.NOW, params);
    },
    select: (data?: any[]) => {
      // fetchData endpoint doesnt always give ratings in the right order of competitors.
      // therefore we need to sort it according to our community&competitor list.
      return [community, ...competitors].map((community: any) =>
        data?.find((communityRating: any) => communityRating.community_id === community.id)
      );
    },
    enabled: Boolean(community),
    refetchOnWindowFocus: false,
  });

  const { data: feesIncentives, isLoading: feesLoading } = useQuery({
    queryKey: ['CMASummary', 'feesIncentive', community?.id],
    queryFn: () => {
      const fetchAllFeesIncentives = async (community: any, competitors: any[]) => {
        const requests = [getFeesIncentives(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getFeesIncentives(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCommunityFeeResponseToData(item)));
      };

      return fetchAllFeesIncentives(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: careFees, isLoading: careFeesLoading } = useQuery({
    queryKey: ['CMASummary', 'careFees', community?.id],
    queryFn: () => {
      const fetchAllCareFees = async (community: any, competitors: any[]) => {
        const requests = [getCareFees(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getCareFees(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCareFeeResponseToData(item)));
      };

      return fetchAllCareFees(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: occupancies, isLoading: occupanciesLoading } = useQuery({
    queryKey: ['CMASummary', 'occupancies', community?.id],
    queryFn: () => {
      const fetchAllOccupancies = async (community: any, competitors: any[]) => {
        const requests = [getOccupancies(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getOccupancies(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) =>
          response.map((occupancy: any) => mapCommunityOccupancyResponseToData(occupancy))
        );
      };

      return fetchAllOccupancies(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: livingInfo, isLoading: livingInformationLoading } = useQuery({
    queryKey: ['CMASummary', 'livingInfo', community?.id],
    queryFn: () => {
      const fetchAllLivingInformation = async (community: any, competitors: any[]) => {
        const requests = [getLivingInfo(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getLivingInfo(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) => response.map((livingInfo: any) => mapCommunityLivingInfoToData(livingInfo)));
      };

      return fetchAllLivingInformation(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: livingInfoGroups, isLoading: livingInformationGroupsLoading } = useQuery({
    queryKey: ['CMASummary', 'livingInfoByApartmentTypeGroup', community?.id],
    queryFn: () => {
      const fetchAllLivingInformation = async (community: any, competitors: any[]) => {
        const requests = [getLivingInfoGrouped(community.id)];
        competitors.forEach((competitor) => {
          requests.push(getLivingInfoGrouped(competitor.id));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) =>
          response.map((livingInfoGroup: any) => mapLivingInfoByApartmentTypeGroupInbound(livingInfoGroup))
        );
      };

      return fetchAllLivingInformation(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const loading =
    ratingsLoading ||
    feesLoading ||
    careFeesLoading ||
    livingInformationGroupsLoading ||
    livingInformationLoading ||
    occupanciesLoading;

  return { loading, ratings, feesIncentives, careFees, occupancies, livingInfo, livingInfoGroups };
};
