import React, { useContext } from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { SELECTOR_ITEM_TYPES } from './constants';
import { SidebarContext } from './SidebarContext';
import SidebarSelectorDropdownCompanySelector from './SidebarSelectorDropdownCompanySelector';
import SidebarSelectorDropdownControls from './SidebarSelectorDropdownControls';
import SidebarSelectorDropdownList from './SidebarSelectorDropdownList';
import { USER_LEVELS } from '../../../constants';

const Container = styled.div`
  position: absolute;
  margin-left: 4px;
  width: 450px;
  box-shadow: 0 2px 10px 0 var(--box-shadow-gray-dark);
  border: solid 1px var(--line-gray);
  background-color: #fff;
  z-index: 100;
`;

const SidebarSelectorDropdown = () => {
  const { selectedClient } = useAppSelector((state) => state.global);
  const { selectedItem } = useContext(SidebarContext);

  const showCompanySelector =
    selectedClient?.level != USER_LEVELS.MANAGEMENT_COMPANY && selectedItem?.type != SELECTOR_ITEM_TYPES.COMPANY;

  return (
    <Container>
      {showCompanySelector && <SidebarSelectorDropdownCompanySelector />}
      <SidebarSelectorDropdownControls />
      <SidebarSelectorDropdownList />
    </Container>
  );
};

export default SidebarSelectorDropdown;
