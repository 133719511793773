import React, { createContext, useContext } from 'react';

import { ApartmentTypeGroups } from 'store/slices/globalSlice';

type CMASummaryContextType = {
  loading: boolean;
  cmaSummaryData?: any[];
  community?: any;
  competitors: any[];
  apartmentTypeGroups: ApartmentTypeGroups[];
};

export const CMASummaryContext = createContext<CMASummaryContextType>({
  loading: false,
  cmaSummaryData: undefined,
  community: undefined,
  competitors: [],
  apartmentTypeGroups: [],
});

export const useCMASummaryContext = () => useContext(CMASummaryContext);
