import { uuid } from 'short-uuid';

import { DataItemType } from 'common/Widgets/Graph/types';
import { LivingType } from 'pageComponents/AddCommunityCompetitor/accommodation/constants';
import { ApartmentTypeGroups } from 'store/slices/globalSlice';

import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
  CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE,
  CMA_SUMMARY_DATA_ROW_TYPE,
  CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
  CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
} from './constants';
import { CMASummaryIncentive } from './types';
import { CARE_FEES_TYPES, LEVELS } from '../../pages/price_healing/Fees/constants';

export const isRowExpandable = (cmaSummaryRowType: string) => {
  return [CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW, CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW].includes(cmaSummaryRowType);
};

const addCMALivingTypeRows = ({
  cmaSummaryData,
  livingtypes,
  occupancies,
  livingInfo,
  feesIncentives,
  careFees,
  apartmentTypeGroups,
  livingInfoGroups,
}: {
  cmaSummaryData: any[];
  livingtypes: LivingType[];
  occupancies?: any[][];
  livingInfo?: any[][];
  careFees?: any[][];
  feesIncentives?: CMASummaryIncentive[][];
  apartmentTypeGroups?: ApartmentTypeGroups[];
  livingInfoGroups?: any[][];
}) => {
  livingtypes.forEach((livingType) => {
    let hasApartmentTypeGroupRows = false;

    // Generating Community Accommodation Group rows
    const temporaryApartmentGroupRows: any[] = [];

    apartmentTypeGroups?.forEach((apartmentTypeGroup) => {
      let hasCurrentLivingInfoGroup = false;

      const groupUUID = uuid();
      const apartmentTypeGroupRow: any = [
        {
          key: groupUUID,
          title: apartmentTypeGroup.name,
          subtitle: livingType.type,
          type: CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
          level: 1,
        },
      ];

      livingInfoGroups?.forEach((group) => {
        const currentLivingInfoGroup = group.find(
          (item) => item.apartmentTypeGroupId === apartmentTypeGroup.id && item.livingType === livingType.id
        );
        if (currentLivingInfoGroup) {
          hasCurrentLivingInfoGroup = true;
          apartmentTypeGroupRow.push([currentLivingInfoGroup.baseRent, currentLivingInfoGroup.squareFootage]);
        } else {
          apartmentTypeGroupRow.push([]);
        }
      });

      // Push APARTMENT TYPE GROUP row only if there is at least one currentLivingInfoGroup
      if (hasCurrentLivingInfoGroup) {
        hasApartmentTypeGroupRows = true;
        temporaryApartmentGroupRows.push(apartmentTypeGroupRow);

        apartmentTypeGroup.apartmentTypes.forEach((apartmentType) => {
          let rowContainsData = false;
          const livingDataRow: any = [
            {
              key: uuid(),
              parent: groupUUID,
              title: apartmentType.type,
              subtitle: livingType.type,
              type: CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
              level: 2,
            },
          ];

          livingInfo?.forEach((communityLivingInfo) => {
            const selectedCommunityLivingInfo = communityLivingInfo.find(
              (item) => item.livingType === livingType.type && item.apartmentType === apartmentType.type
            );
            if (selectedCommunityLivingInfo) {
              rowContainsData = true;
              livingDataRow.push([selectedCommunityLivingInfo.baseRent, selectedCommunityLivingInfo.squareFootage]);
            } else {
              livingDataRow.push([]);
            }
          });

          if (rowContainsData) {
            temporaryApartmentGroupRows.push(livingDataRow);
          }
        });
      }
    });

    // Generating Community Fees rows
    const feesRows: any[] = [];
    if (feesIncentives?.length) {
      const livingTypeFeesIncentives = feesIncentives.map((communityFeeIncentives: CMASummaryIncentive[]) =>
        communityFeeIncentives.filter((item: CMASummaryIncentive) => item?.livingTypeId == livingType.id)
      );

      Object.values(CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE).forEach(
        (communityFeeType: { id: string; label: string; field: string }) => {
          const communitiesFeeValues = livingTypeFeesIncentives.map((item: CMASummaryIncentive[]) => {
            if (item[0]) {
              const entry = item[0];
              const fieldName: keyof CMASummaryIncentive = communityFeeType.field;
              const isCommunityFee = communityFeeType.id === CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE.COMMUNITY_FEE.id;
              const communityFeeEquivalentFactor = isCommunityFee && entry.frequency;

              const value =
                communityFeeEquivalentFactor && entry.communityFeeEquivalent
                  ? entry.communityFeeEquivalent
                  : item[0][fieldName];
              return [value, communityFeeEquivalentFactor];
            } else {
              return [];
            }
          });

          if (communitiesFeeValues.some((entry: any) => entry && typeof entry[0] === 'number')) {
            hasApartmentTypeGroupRows = true;
            const individualFeeRow = {
              key: uuid(),
              title: communityFeeType.label,
              subtitle: livingType.type,
              type: CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
            };

            feesRows.push([individualFeeRow, ...communitiesFeeValues]);
          }
        }
      );
    }

    if (careFees?.length) {
      const livingTypeCareFees = careFees
        ?.map((communityCareFees: any[]) =>
          communityCareFees.filter((item: any) => item?.livingTypeId == livingType.id)
        )
        .map((communityCareFees: any[]) => (communityCareFees?.length ? communityCareFees[0] : []));

      const communityCareFees = livingTypeCareFees.map((communityCareFee: any) => {
        if (!communityCareFee) {
          return [];
        }
        let value = undefined;
        const type = communityCareFee?.feesType;
        let subtitle = '';

        if (communityCareFee.feesTypeId === CARE_FEES_TYPES.LEVELS) {
          const levelsList = [
            communityCareFee?.level1,
            communityCareFee?.level2,
            communityCareFee?.level3,
            communityCareFee?.level4,
            communityCareFee?.level5,
            communityCareFee?.level6,
            communityCareFee?.level7,
            communityCareFee?.level8,
          ];

          const presentLevels = levelsList.filter((item: number) => Boolean(item));
          const levelsCount = presentLevels.length;
          const sum = levelsList.reduce((accumulator, current) => accumulator + current, 0);
          const averageLevelsValue = sum / levelsCount;

          value = averageLevelsValue;
          subtitle = `${levelsCount} ${levelsCount === 1 ? 'Level' : 'Levels'}`;
        }

        return [value, type, subtitle];
      });

      const hasCareFeesRow = communityCareFees.some(
        (communityCareFee: any) => communityCareFee?.length && Boolean(communityCareFee[0])
      );
      const hasCareFeesGroup = livingTypeCareFees.some(
        (communityCareFee: any) => communityCareFee?.feesTypeId === CARE_FEES_TYPES.LEVELS
      );

      const groupUUID = uuid();
      const careFeesGroupRow: any = [
        {
          key: groupUUID,
          title: 'Care Fees',
          subtitle: livingType.type,
          living_type: livingType.type,
          type: CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
          level: hasCareFeesGroup ? 1 : undefined,
        },
        ...communityCareFees,
      ];

      if (hasCareFeesRow) {
        temporaryApartmentGroupRows.push(careFeesGroupRow);
        hasApartmentTypeGroupRows = true;
      }

      // Push APARTMENT TYPE GROUP row only if there is at least one currentLivingInfoGroup
      if (hasCareFeesGroup) {
        const careFeeLevelsRows = LEVELS.map((field: string, index: number) => {
          const levelsTitleCell = {
            key: uuid(),
            parent: groupUUID,
            title: `Level ${index + 1}`,
            living_type: livingType.type,
            type: CMA_SUMMARY_CARE_FEE_DATA_ROW,
            level: 2,
          };

          const levelsValuesCells = livingTypeCareFees.map((communityCareFee: any) => communityCareFee[field]);
          const hasAnyLevelValues = levelsValuesCells.some((item: any) => Boolean(item));

          // If there is no values for particular level dicard that row from the data
          return hasAnyLevelValues ? [levelsTitleCell, ...levelsValuesCells] : [];
        });

        // Only leave Level row where at least one Community has value.
        const filteredCareFeeLevelsRows = careFeeLevelsRows.filter((item: any[]) => item.length);
        temporaryApartmentGroupRows.push(...filteredCareFeeLevelsRows);
      }
    }

    // Generating Living Type header row containing occupancies data
    const livingTypeRows = [];
    if (occupancies?.length) {
      const communityOccupancies = occupancies.map((item) => {
        const occupancy = item?.find((occupancy: any) => occupancy.livingType === livingType.type);
        if (occupancy) {
          const { occupancyPercentage, totalApartmentsUnits, freeApartmentsUnits } = occupancy;
          return [occupancyPercentage, totalApartmentsUnits, freeApartmentsUnits];
        }

        return [];
      });

      // Display Occupancy Row
      const hasOccupanciesData = communityOccupancies.some((item: any[]) => item.length); // Generating Community Occupancies rows
      const occupancyLivingTypeRow = {
        key: uuid(),
        title: livingType.type,
        hasOccupancies: hasOccupanciesData,
        subtitle: hasOccupanciesData ? 'Occupancy' : undefined,
        type: CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
      };

      livingTypeRows.push([occupancyLivingTypeRow, ...communityOccupancies]);

      if (hasOccupanciesData) {
        hasApartmentTypeGroupRows = true;
      }
    }

    // Push the LIVING TYPE OCCUPANCY row if it has apartment group rows, and push its children after
    if (hasApartmentTypeGroupRows) {
      cmaSummaryData.push(...livingTypeRows);
      cmaSummaryData.push(...temporaryApartmentGroupRows);
      cmaSummaryData.push(...feesRows);
    }
  });
};

type CMASummaryDataProps = {
  community: any;
  competitors: any[];
  ratings?: DataItemType[];
  livingtypes: LivingType[];
  feesIncentives?: CMASummaryIncentive[][];
  careFees?: any;
  occupancies?: any[][];
  livingInfo?: any[][];
  apartmentTypeGroups: ApartmentTypeGroups[];
  livingInfoGroups?: any[][];
};

export const getCMASummaryData = ({
  community,
  competitors,
  ratings,
  livingtypes,
  feesIncentives,
  careFees,
  occupancies,
  livingInfo,
  apartmentTypeGroups,
  livingInfoGroups,
}: CMASummaryDataProps) => {
  if (!community || !competitors) {
    return undefined;
  }

  const cmaSummaryData: any[] = [
    [{ key: uuid(), title: '', type: CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME }],
    [{ key: uuid(), title: 'Address', type: CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS }],
    [{ key: uuid(), title: 'Website', type: CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE }],
    [{ key: uuid(), title: 'Phone', type: CMA_SUMMARY_DATA_ROW_TYPE.PHONE }],
    [{ key: uuid(), title: 'Reviews', subtitle: 'All Sources', type: CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS }],
  ];

  addCMALivingTypeRows({
    cmaSummaryData,
    livingtypes,
    occupancies,
    livingInfo,
    careFees,
    feesIncentives,
    apartmentTypeGroups,
    livingInfoGroups,
  });

  cmaSummaryData.push([
    {
      key: uuid(),
      title: 'Incentives',
      subtitle: 'View community page for more',
      type: CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES,
    },
  ]);

  cmaSummaryData.forEach((dataRow) => {
    const rowMetadata = dataRow[0];

    switch (rowMetadata.type) {
      case CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME: {
        dataRow.push(community.apartmentname);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.apartmentname);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS: {
        dataRow.push(community.apartmentaddress);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.apartmentaddress);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE: {
        dataRow.push(community.website);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.website);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.PHONE: {
        dataRow.push(community.phone_number);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.phone_number);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS: {
        ratings?.forEach((rating) => {
          dataRow.push([rating?.value?.toFixed(1), rating?.total]);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES: {
        if (feesIncentives) {
          dataRow.push(...feesIncentives);
        }
        break;
      }
    }
  });

  return cmaSummaryData;
};

export const mapLivingInfoByApartmentTypeGroupInbound = (item: any) => {
  return {
    apartmentTypeGroupId: item.apartment_type_group,
    baseRent: item.base_rent,
    squareFootage: item.sq_footage,
    livingType: item.living_type,
  };
};

const prepareCMASummaryDataRowForAntdTable = (cmaSummaryDataRow: any) => {
  const rowMetadata = cmaSummaryDataRow[0];
  return {
    key: rowMetadata.key,
    rowContent: cmaSummaryDataRow,
  };
};

export const prepareCMASummaryDataRowsForAntdTable = (expandedRowKeys: string[], cmaSummaryData?: any[]) => {
  const results = cmaSummaryData
    ?.map((row) => prepareCMASummaryDataRowForAntdTable(row))
    .filter((row) => {
      const { rowContent } = row;

      const rowMetadata = rowContent.length ? rowContent[0] : undefined;
      const parentUUID = rowMetadata?.parent;

      return !parentUUID ? true : parentUUID && expandedRowKeys.includes(parentUUID);
    });

  return results;
};
