import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
  CMA_SUMMARY_DATA_ROW_TYPE,
  CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
  CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
} from './constants';

const FIXED_TITLE_COLUMNS = ['Type', 'Field'];
const COLUMN_TYPE = {
  GENERAL_INFO: 'General Info',
  OTHER: 'Other',
};

const formatNumber = (num: number | undefined) => {
  if (num === undefined || num === null) {
    return num;
  }

  // Check if there are non-zero decimals after rounding to two decimal places
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

export const transformCSV = (community: any, data: any) => {
  const csvData: any[] = [];

  data.forEach((row: any) => {
    const rowMetadata = row[0];
    const rowContent = row.slice(1);

    switch (rowMetadata?.type) {
      // Form the header CSV row
      // Fixed columns Type and Field, variable columns of Community and it's Competitors
      case CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME:
        csvData.push([...FIXED_TITLE_COLUMNS, ...rowContent]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS:
        csvData.push([
          COLUMN_TYPE.GENERAL_INFO,
          'Address',
          ...rowContent.map((item: string) => (item ? `"${item}"` : undefined)),
        ]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE:
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Website', ...rowContent]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.PHONE:
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Phone', ...rowContent]);
        break;
      // Concatenate all address parts
      case CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS:
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Rating', ...rowContent.map((item: string[]) => item[0])]);
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Number of reviews', ...rowContent.map((item: string[]) => item[1])]);
        break;
      // Check for presence of occupancies data for Living Type
      case CMA_SUMMARY_LIVING_TYPE_DATA_ROW:
        if (rowMetadata.hasOccupancies) {
          csvData.push([
            rowMetadata.title,
            'Occupancy',
            ...rowContent.map((item: [number, number, number]) => (item[0] ? `${item[0]}%` : undefined)),
          ]);
          csvData.push([
            rowMetadata.title,
            'Total number of units',
            ...rowContent.map((item: [number, number, number]) => item[1]),
          ]);
          csvData.push([
            rowMetadata.title,
            'Occupied number of units',
            ...rowContent.map((item: [number, number, number]) => (item[1] && item[2] ? item[1] - item[2] : undefined)),
          ]);
        }
        break;
      case CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW:
        csvData.push([
          rowMetadata.subtitle,
          `${rowMetadata.title} Rate (Avg)`,
          ...rowContent.map((item: [number, number]) => item[0]),
        ]);
        csvData.push([
          rowMetadata.subtitle,
          `${rowMetadata.title} Sq.Ft. (Avg)`,
          ...rowContent.map((item: [number, number]) => item[1]),
        ]);
        break;
      case CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW:
        csvData.push([
          rowMetadata.subtitle,
          `${rowMetadata.title} Rate`,
          ...rowContent.map((item: [number, number]) => item[0]),
        ]);
        csvData.push([
          rowMetadata.subtitle,
          `${rowMetadata.title} Sq.Ft.`,
          ...rowContent.map((item: [number, number]) => item[1]),
        ]);
        break;
      case CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW:
        csvData.push([rowMetadata.subtitle, rowMetadata.title, ...rowContent.map((item: [number, number]) => item[0])]);
        break;
      case CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW:
        csvData.push([
          rowMetadata.living_type,
          'Care Fee Type',
          ...rowContent.map((item: [number, string, string]) => item[1]),
        ]);
        csvData.push([
          rowMetadata.living_type,
          `Care Fee (Avg)`,
          ...rowContent.map((item: [number, string, string]) => formatNumber(item[0])),
        ]);
        break;
      case CMA_SUMMARY_CARE_FEE_DATA_ROW:
        csvData.push([rowMetadata.living_type, `Care Fee ${rowMetadata.title}`, ...rowContent]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES: {
        const rowIncentivesWithContent = rowContent.map((incentives: any[]) => [
          ...incentives.filter((incentive: any) => Boolean(incentive.incentive)),
        ]);
        const incentivesMaxCount = Math.max(...rowIncentivesWithContent.map((incentives: any[]) => incentives.length));

        for (let i = 0; i < incentivesMaxCount; i++) {
          csvData.push([
            COLUMN_TYPE.OTHER,
            'Incentive',
            ...rowIncentivesWithContent.map((incentives: any[]) =>
              incentives[i] ? `"${incentives[i].incentive}"` : undefined
            ),
          ]);
        }
        break;
      }
    }
  });

  // Merge available data together
  let csvContent = '';
  csvData.forEach((row: any) => {
    csvContent += row.join(',') + '\n';
  });

  // Generate binary Blob and initiate download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  const currentDate = new Date().toLocaleDateString();
  const communityName = community?.name.split(' ').join('_');
  const filename = `CMA_Summary_${communityName}_${currentDate}.csv`;

  const link = document.createElement('a');
  link.setAttribute('href', objUrl);
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
