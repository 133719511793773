import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  careFee: any[];
};

const CMASummaryTableDataCareFeesGroup = ({ careFee }: Props) => {
  const [value, type, subtitle] = careFee;

  return (
    <div>
      {value && <Title>${value.toLocaleString(undefined, { maximumFractionDigits: 0 })} avg.</Title>}
      {!value && <Title>{type}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  );
};

export default CMASummaryTableDataCareFeesGroup;
