import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';
import useArrayBoundary from 'utils/hooks/useArrayBoundary';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryStyledTable from './CMASummaryStyledTable';
import CMASummaryTableColumnHeader from './CMASummaryTableColumnHeader';
import CMASummaryTableDataColumn from './CMASummaryTableDataColumn';
import CMASummaryTableTitleColumn from './CMASummaryTableTitleColumn';
import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_TABLE_COLUMNS_OFFSET,
} from './constants';
import { prepareCMASummaryDataRowsForAntdTable } from './utils';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 15px 20px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  background: white;
  padding-top: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 18px;
  font-family: var(--font-bold);
  padding-left: 20px;
`;

const CMASummaryTable = () => {
  const { cmaSummaries } = useAppSelector((state) => state.global);
  const { loading, cmaSummaryData, community, competitors } = useCMASummaryContext();
  const { cmaSummaryId } = useParams();
  const {
    minimum: competitorsMinIndex,
    maximum: competitorsMaxIndex,
    moveLeft: scrollCompetitorsLeft,
    moveRight: scrollCompetitorsRight,
  } = useArrayBoundary({ initialMinimum: 0, initialMaximum: 2 });
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  if (!cmaSummaryId) {
    return null;
  }

  const cmaSummary = cmaSummaries.find((item) => item.id === parseInt(cmaSummaryId));
  const filteredCompetitors = competitors?.filter(
    (competitor: any, index: number) => index >= competitorsMinIndex && index <= competitorsMaxIndex
  );

  const columns: any[] = [
    {
      title: '',
      width: '20%',
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableTitleColumn
          cmaSummaryRow={cmaSummaryRow.rowContent}
          expandedRowKeys={expandedRowKeys}
          setExpandedRowKeys={setExpandedRowKeys}
        />
      ),
    },
    {
      title: community?.apartmentname,
      width: '20%',
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableDataColumn cmaSummaryRow={cmaSummaryRow.rowContent} index={1} />
      ),
    },
  ];

  const disableLeftScroll = competitorsMinIndex === 0;
  const disableRightScroll = competitorsMaxIndex === (competitors?.length || 0) - 1;
  filteredCompetitors?.forEach((competitor: any, index: number) => {
    const indexWithOffset = CMA_SUMMARY_TABLE_COLUMNS_OFFSET + competitorsMinIndex + index;
    columns.push({
      title: () => (
        <CMASummaryTableColumnHeader
          index={index}
          community={competitor}
          filteredCompetitorsCount={filteredCompetitors.length}
          totalCompetitorsCount={competitors?.length || 0}
          scrollLeft={scrollCompetitorsLeft}
          scrollRight={scrollCompetitorsRight}
          disableLeft={disableLeftScroll}
          disableRight={disableRightScroll}
        />
      ),
      width: '20%',
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableDataColumn cmaSummaryRow={cmaSummaryRow.rowContent} index={indexWithOffset} />
      ),
    });
  });

  const getRowClassName = (record: any, index: number) => {
    if (record?.rowContent?.length) {
      const rowMetadata = record?.rowContent[0];
      return [CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW, CMA_SUMMARY_CARE_FEE_DATA_ROW].includes(rowMetadata.type)
        ? 'cma-summary-apartment-type-data-row'
        : '';
    }

    return '';
  };

  return (
    <Container>
      <Title>{cmaSummary?.name}</Title>
      <CMASummaryStyledTable
        loading={loading}
        dataSource={prepareCMASummaryDataRowsForAntdTable(expandedRowKeys, cmaSummaryData?.slice(1))}
        columns={columns}
        pagination={false}
        rowClassName={getRowClassName}
        scroll={{ y: 'max-content' }}
        rowKey="key"
      />
    </Container>
  );
};

export default CMASummaryTable;
