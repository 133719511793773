import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { CARE_LEVELS_STATS_TYPE, CARE_LEVELS_STATS_TYPE_LABELS } from 'components/View/constants';

type Props = {
  statsType: number;
  onSelect: any;
};

const Container = styled.div`
  border-top: 1px solid var(--line-gray);
  max-height: 200px;
  padding: 10px 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 105px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
  background-color: #f1f1f1;
`;

const FilterButton = styled.div`
  width: 32px;
  height: 20px;
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;

  &.active {
    background-color: #007aff;
    color: var(--white);
  }
`;

const CareLevelFilterOtherOptions = ({ statsType, onSelect }: Props) => {
  return (
    <Container>
      <ButtonsWrapper>
        {Object.entries(CARE_LEVELS_STATS_TYPE).map(([key, value]) => (
          <FilterButton
            key={key}
            className={classNames({
              active: value === statsType,
            })}
            onClick={() => onSelect(value)}
          >
            {CARE_LEVELS_STATS_TYPE_LABELS[value]}
          </FilterButton>
        ))}
      </ButtonsWrapper>
    </Container>
  );
};

export default CareLevelFilterOtherOptions;
