import React, { ReactNode } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';
import { isLocationMatchingMenuItem } from 'utils/helpers/locationMatch';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 12px;
`;

const MenuItem = styled.div`
  padding: 15px 30px;

  &:hover {
    background-color: var(--sidebar-selector-border);
  }

  &.selected {
    background-color: var(--sidebar-selector-border);
  }
`;

const IconWrapper = styled.span`
  font-size: 14px;
  margin-right: 10px;
`;

interface Props {
  to: string;
  pattern?: string;
  label: string;
  icon?: ReactNode;
}

const SidebarMenuCmaSummary = () => {
  const { cmaSummaries, selectedCommunity } = useAppSelector((state) => state.global);

  return (
    <>
      {cmaSummaries?.map((item) => (
        <StyledLink key={item.id} to={`/community/${selectedCommunity?.id}/cma-summary/${item.id}`}>
          <MenuItem>CMA Summary</MenuItem>
        </StyledLink>
      ))}
    </>
  );
};

export default SidebarMenuCmaSummary;
