import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import CMASummaryBreadcrumbs from './CMASummaryBreadcrumbs';
import { useCMASummaryContext } from './CMASummaryContext';
import { transformCSV } from './csv';
import Button from '../lib/Button';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 15px 30px;
  border-bottom: solid 1px var(--line-gray);
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 40px;
`;

const CMASummaryHeader = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { loading, cmaSummaryData } = useCMASummaryContext();

  return (
    <Container>
      <div>
        <CMASummaryBreadcrumbs />
        <Title>{selectedCommunity?.name}</Title>
      </div>
      <Actions>
        <Button disabled={loading} onClick={() => transformCSV(selectedCommunity, cmaSummaryData)}>
          Download as CSV
        </Button>
      </Actions>
    </Container>
  );
};

export default CMASummaryHeader;
