import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { CMASummaryContext } from './CMASummaryContext';
import CMASummaryHeader from './CMASummaryHeader';
import CMASummaryTable from './CMASummaryTable';
import { useFetchCMASummaryData } from './customHooks';
import { getCMASummaryData } from './utils';
import { ACTOR_TYPE } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const CMASummaryTableContainer = styled.div`
  height: 100%;
  background-color: var(--background-gray);
`;

const CMASummary = () => {
  const { selectedCommunity, communityList, livingtypes, apartmentTypeGroups } = useAppSelector(
    (state) => state.global
  );

  let community, competitors;
  if (selectedCommunity?.type === ACTOR_TYPE.COMMUNITY) {
    community = communityList.find((community) => community?.id === selectedCommunity?.id);
    competitors = community?.competitors;
  } else {
    community = communityList.find((community) => community?.id === selectedCommunity?.parentId);
    competitors = [community?.competitors.find((competitor: any) => competitor.id === selectedCommunity?.id)];
  }

  const { loading, ratings, feesIncentives, careFees, occupancies, livingInfo, livingInfoGroups } =
    useFetchCMASummaryData(community, competitors);

  return (
    <CMASummaryContext.Provider
      value={{
        cmaSummaryData: getCMASummaryData({
          community,
          competitors,
          ratings,
          livingtypes,
          feesIncentives,
          careFees,
          occupancies,
          livingInfo,
          apartmentTypeGroups,
          livingInfoGroups,
        }),
        loading,
        community,
        competitors,
        apartmentTypeGroups,
      }}
    >
      <Container>
        <CMASummaryHeader />
        <CMASummaryTableContainer>
          <CMASummaryTable />
        </CMASummaryTableContainer>
      </Container>
    </CMASummaryContext.Provider>
  );
};

export default CMASummary;
