import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Button from 'components/lib/Button';

import { CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW, CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW } from './constants';
import { isRowExpandable } from './utils';

interface TitleProps {
  level: number;
}

const Container = styled.div<TitleProps>`
  display: flex;
  flex-flow: column;
  padding-left: ${(props) => `${(props?.level ?? 0) * 30}px`};

  &.indented {
    padding-left: 30px;
  }
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
`;

const Subtitle = styled.div`
  font-family: var(--font-medium);
  font-size: 12px;
  color: var(--text-secondary);
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 21px;
  left: 22px;
  padding: 0px;
  height: 16px;
  width: 16px;
  box-shadow: none;
`;

type Props = {
  cmaSummaryRow: any;
  expandedRowKeys: string[];
  setExpandedRowKeys: (keys: string[]) => void;
};

const CMASummaryTableTitleColumn = ({ cmaSummaryRow, expandedRowKeys, setExpandedRowKeys }: Props) => {
  const rowMetadata = cmaSummaryRow[0];
  const subtitle = rowMetadata?.subtitle || null;

  const toggleExpanded = () => {
    const rowKey = rowMetadata.key;

    if (expandedRowKeys.includes(rowKey)) {
      setExpandedRowKeys([...expandedRowKeys.filter((key) => key !== rowKey)]);
    } else {
      setExpandedRowKeys([...expandedRowKeys, rowKey]);
    }
  };
  const cellClasses = classNames({
    indented:
      [CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW, CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW].includes(rowMetadata?.type) &&
      !rowMetadata?.level,
  });

  return (
    <Container level={rowMetadata?.level} className={cellClasses}>
      {isRowExpandable(rowMetadata.type) && Boolean(rowMetadata?.level) && (
        <StyledButton type="text" onClick={toggleExpanded}>
          <img
            src={expandedRowKeys.includes(rowMetadata.key) ? '/icons/expand-more.svg' : '/icons/chevron-right.svg'}
            style={{ width: '16px', height: '16px' }}
          />
        </StyledButton>
      )}
      <Title>{rowMetadata?.title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

export default CMASummaryTableTitleColumn;
