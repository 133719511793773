import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 14px;
  color: var(--link-blue);
`;

type Props = { phone: string };

const CMASummaryTableDataPhone = ({ phone }: Props) => <Container title={phone}>{phone}</Container>;

export default CMASummaryTableDataPhone;
