export const COMPETITOR_COUNT_LIMIT = 3;

export const CMA_SUMMARY_DATA_ROW_TYPE = {
  COMMUNITY_NAME: 'COMMUNITY_NAME',
  ADDRESS: 'ADDRESS',
  WEBSITE: 'WEBSITE',
  PHONE: 'PHONE',
  REVIEWS: 'REVIEWS',
  INCENTIVES: 'INCENTIVES',
};

export const CMA_SUMMARY_LIVING_TYPE_DATA_ROW = 'CMA_SUMMARY_LIVING_TYPE_DATA_ROW';
export const CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW = 'CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW';
export const CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW = 'CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW';
export const CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW = 'CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW';
export const CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW = 'CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW';
export const CMA_SUMMARY_CARE_FEE_DATA_ROW = 'CMA_SUMMARY_CARE_FEE_DATA_ROW';

export const CMA_SUMMARY_TABLE_COLUMNS_OFFSET = 2;

export const CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE = {
  COMMUNITY_FEE: {
    id: 'COMMUNITY_FEE',
    label: 'Community Fee',
    field: 'communityFee',
  },
  SECOND_PERSON_FEE: {
    id: 'SECOND_PERSON_FEE',
    label: 'Second Person Fee',
    field: 'secondPersonFee',
  },
  PET_FEE: {
    id: 'PET_FEE',
    label: 'Pet Fee',
    field: 'petFee',
  },
  PARKING_FEE: {
    id: 'PARKING_FEE',
    label: 'Parking Fee',
    field: 'parkingFee',
  },
  RESPITE_FEE: {
    id: 'RESPITE_FEE',
    label: 'Respite Fee',
    field: 'respiteFee',
  },
  ADDITIONAL_FEE: {
    id: 'ADDITIONAL_FEE',
    label: 'Additional Fee',
    field: 'additionalFee',
  },
  MOVE_IN_FEE: {
    id: 'MOVE_IN_FEE',
    label: 'Move In Fee',
    field: 'moveInFee',
  },
  ENTRANCE_FEE: {
    id: 'ENTRANCE_FEE',
    label: 'Entrance Fee',
    field: 'entranceFee',
  },
  DEPOSIT: {
    id: 'DEPOSIT',
    label: 'Deposit',
    field: 'deposit',
  },
  EMERGENCY_PENDANT_FEE: {
    id: 'EMERGENCY_PENDANT_FEE',
    label: 'Emergency Pendant Fee',
    field: 'emergencyPendantFee',
  },
};
