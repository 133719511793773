import React from 'react';

import CMASummaryTableDataAddress from './CMASummaryTableDataAddress';
import CMASummaryTableDataCareFees from './CMASummaryTableDataCareFees';
import CMASummaryTableDataCareFeesGroup from './CMASummaryTableDataCareFeesGroup';
import CMASummaryTableDataIncentives from './CMASummaryTableDataIncentives';
import CMASummaryTableDataLivingInfo from './CMASummaryTableDataLivingInfo';
import CMASummaryDataTableLivingInfoGroup from './CMASummaryTableDataLivingInfoGroup';
import CMASummaryTableDataLivingTypeFee from './CMASummaryTableDataLivingTypeFee';
import CMASummaryTableDataLivingTypeOccupancy from './CMASummaryTableDataLivingTypeOccupancy';
import CMASummaryTableDataPhone from './CMASummaryTableDataPhone';
import CMASummaryTableDataReview from './CMASummaryTableDataReview';
import CMASummaryTableDataWebsite from './CMASummaryTableDataWebsite';
import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
  CMA_SUMMARY_DATA_ROW_TYPE,
  CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
  CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
} from './constants';

type Props = {
  cmaSummaryRow: any;
  index: number;
};

const CMASummaryTableDataColumn = ({ cmaSummaryRow, index }: Props) => {
  let content;
  const rowMetadata = cmaSummaryRow[0];
  const dataItem = cmaSummaryRow[index];

  if (rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS) {
    content = <CMASummaryTableDataAddress address={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE) {
    content = <CMASummaryTableDataWebsite website={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.PHONE) {
    content = <CMASummaryTableDataPhone phone={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS) {
    content = <CMASummaryTableDataReview reviews={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES) {
    content = <CMASummaryTableDataIncentives incentives={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_LIVING_TYPE_DATA_ROW) {
    content = <CMASummaryTableDataLivingTypeOccupancy occupancy={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW) {
    content = <CMASummaryTableDataLivingInfo livingInfo={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW) {
    content = <CMASummaryDataTableLivingInfoGroup livingInfo={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW) {
    content = <CMASummaryTableDataCareFeesGroup careFee={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_CARE_FEE_DATA_ROW) {
    content = <CMASummaryTableDataCareFees careFeeLevel={dataItem} />;
  } else if (rowMetadata.type === CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW) {
    content = <CMASummaryTableDataLivingTypeFee fee={dataItem} />;
  }

  return <>{content}</>;
};

export default CMASummaryTableDataColumn;
