import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

type Props = {
  careFeeLevel: number;
};

const CMASummaryTableDataCareFees = ({ careFeeLevel }: Props) => (
  <div>
    {typeof careFeeLevel == 'number' && <Title>${careFeeLevel.toLocaleString()}</Title>}
    {typeof careFeeLevel != 'number' && '-'}
  </div>
);

export default CMASummaryTableDataCareFees;
