import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { DeleteFilled } from '@ant-design/icons/lib';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { CommunityFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import { mapCommunityFeeResponseToData } from './utils';
import { deleteFeesIncentives, getFeesIncentives, getLivingInfo } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { GLOBAL_DATE_FORMAT, MEDIA_BREAK_POINTS, STATUS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeesFormHistoryCommunityFees = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;
  const missingDeletionPermission = permissions?.indexOf('PricingAssistant.delete_feesincentives') == -1;

  const { showMessage } = useRootContext();
  const { communityId } = useFeesContext();

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  const {
    data: communityFeesIncentives,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['communityFeesIncentives', 'historical', communityId],
    queryFn: () => getFeesIncentives(communityId as number, { active: 0 }),
    select: (data: any[]) => {
      const formatted_data: CommunityFeeDateType[] = data?.map((item: any) => mapCommunityFeeResponseToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch historical Community Fees');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const deleteCommunityFeesIncentive = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    deleteFeesIncentives(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Historical Community Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete historical Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const getTooltipContent = (record: any) => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingType',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Community Fee',
      dataIndex: 'communityFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Community Fee Equivalent',
      dataIndex: 'frequencyName',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '2nd Person Fee',
      dataIndex: 'secondPersonFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Pet Fee',
      dataIndex: 'petFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Parking Fee',
      dataIndex: 'parkingFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Respite Fee',
      dataIndex: 'respiteFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Additional Fee',
      dataIndex: 'additionalFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Move In Fee',
      dataIndex: 'moveInFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Entrance Fee',
      dataIndex: 'entranceFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Deposit Fee',
      dataIndex: 'deposit',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Deposit Equivalent',
      dataIndex: 'depositEquivalentFactor',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Emergency Pendant Fee',
      dataIndex: 'emergencyPendantFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Incentives Value',
      dataIndex: 'incentiveValue',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Incentives',
      dataIndex: 'incentive',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      render: (value: Dayjs, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value && value.format(GLOBAL_DATE_FORMAT)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      hidden: missingDeletionPermission,
      render: (_: any, record: Partial<CommunityFeeDateType>) => {
        return (
          <ButtonContainer>
            <Popconfirm
              title="Are you sure you want to delete this record?"
              placement="left"
              onConfirm={() => deleteCommunityFeesIncentive(record)}
            >
              <Button
                size="small"
                type="link"
                danger
                icon={<DeleteFilled />}
                disabled={dataLoading === STATUS.LOADING}
              />
            </Popconfirm>
          </ButtonContainer>
        );
      },
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>Community Fees</Typography.Title>
      <Table
        bordered
        loading={dataLoading === STATUS.LOADING || isLoading}
        scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
        columns={columns}
        dataSource={communityFeesIncentives ?? []}
      />
    </div>
  );
};

export default FeesFormHistoryCommunityFees;
