import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import styled from 'styled-components';

import { getAllCommunities } from 'apis/CommunityAPI';
import { createShopRequests } from 'apis/ShopRequestAPI';
import { useRootContext } from 'components/layout/RootContext';
import Modal from 'components/lib/Modal';
import { getDateString } from 'helpers/dates';

import CreateShopRequestModalContent from './CreateShopRequestModalContent';
import { ShopRequestModalContext } from './ShopRequestModalContext';
import { COMMUNITY_TYPE } from '../../../constants';
import {
  END_REPEAT_VALUES,
  MYSTERY_SHOP_MODAL_REQUEST_FROM,
  MYSTERY_SHOP_REQUEST_DUE_DATES,
  SHOP_REQUEST_REPEAT_OCCURENCES,
  SHOP_REQUEST_STATUS,
} from '../constants';
import { useShopRequestsContext } from '../ShopRequestsContext';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { MysteryShopRequestGroup, ShopRequestCreationObject } from '../types';
import { createShopRequestOutbound, getShopRequestName } from '../utils';

const StyledModal = styled(Modal)`
  &&& .ant-modal {
    max-width: 1040px;
  }

  .ant-modal-close {
    top: 22px;
  }

  &&& .ant-modal-body {
    padding: 0;
  }

  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }

  .ant-modal-content {
    max-width: 1040px;

    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

type Props = {
  open: boolean;
  onCancel: () => void;
};

const CreateShopRequestModal = ({ open, onCancel }: Props) => {
  const { showMessage } = useRootContext();
  const queryClient = useQueryClient();
  const { staffUsers } = useShopRequestsContext();
  const { filters, ordering, setFilters } = useShopRequestsDataContext();
  const [isLoading, setLoading] = useState(false);

  const [shopRequests, setShopRequests] = useState<ShopRequestCreationObject[]>([]);

  const [requestFrom, setRequestFrom] = useState(MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [dueDate, setDueDate] = useState<string>(MYSTERY_SHOP_REQUEST_DUE_DATES.IN_3_DAYS);
  const [repeat, setRepeat] = useState<number>(SHOP_REQUEST_REPEAT_OCCURENCES.EVERY_MONTH);
  const [repeatEnabled, setRepeatEnabled] = useState<boolean>(false);
  const [endRepeat, setEndRepeat] = useState(END_REPEAT_VALUES.AFTER_1_YEAR);
  const [specificDueDate, setSpecificDueDate] = useState(new Date());
  const [shopperId, setShopperId] = useState<number | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [shopForBaseCommunity, setShopForBaseCommunity] = useState(false);
  const [selectedBaseCommunity, setSelectedBaseCommunity] = useState<any>(null);
  const [baseCommunityIL, setBaseCommunityIL] = useState(false);
  const [baseCommunityAL, setBaseCommunityAL] = useState(false);
  const [baseCommunityMC, setBaseCommunityMC] = useState(false);

  const isOkButtonDisabled = isLoading || (!shopRequests.length && !shopForBaseCommunity);

  const { data: companyCommunities } = useQuery({
    queryKey: ['companyCommunities', selectedCompany],
    queryFn: () =>
      getAllCommunities({
        companies: [selectedCompany],
        type: COMMUNITY_TYPE.COMMUNITY,
      }),
    enabled: !!selectedCompany,
    refetchOnWindowFocus: false,
  });

  const { data: companyCompetitors } = useQuery({
    queryKey: ['companyCompetitors', selectedCompany, selectedBaseCommunity],
    queryFn: () =>
      getAllCommunities({
        companies: [selectedCompany],
        type: COMMUNITY_TYPE.COMPETITOR,
        apartment_id: [selectedBaseCommunity?.id],
      }),
    enabled: !!selectedCompany && !!selectedBaseCommunity,
    refetchOnWindowFocus: false,
  });

  const { data: communityList } = useQuery({
    queryKey: ['allCommunitiesList'],
    queryFn: () => getAllCommunities({ type: COMMUNITY_TYPE.COMMUNITY }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => setShopForBaseCommunity(false), [selectedCompany]);

  const remainingCommunities = useMemo(() => {
    if (!communityList) {
      return [];
    }
    if (requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && companyCompetitors) {
      return companyCompetitors.filter(
        (community: any) => !shopRequests.find((request) => request.communityId === community.id)
      );
    }
    return communityList.filter(
      (community: any) => !shopRequests.find((request) => request.communityId === community.id)
    );
  }, [communityList, shopRequests, companyCompetitors]);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const requests = shopRequests.map((r) => ({
        ...r,
        shopperId,
        dueDate: getDateString(dueDate, specificDueDate),
        repeat,
        requestFrom: requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY ? selectedCompany : null,
        status: SHOP_REQUEST_STATUS[0].id,
      }));
      if (shopForBaseCommunity) {
        requests.push({
          communityId: selectedBaseCommunity.id,
          communityName: '',
          independentLiving: baseCommunityIL,
          assistedLiving: baseCommunityAL,
          memoryCare: baseCommunityMC,
          adminNote: null,
          dueDate: getDateString(dueDate, specificDueDate),
          repeat,
          shopperId,
          requestFrom: requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY ? selectedCompany : null,
          status: SHOP_REQUEST_STATUS[0].id,
        });
      }

      const payload: MysteryShopRequestGroup = createShopRequestOutbound(
        getShopRequestName(name, requestFrom, selectedBaseCommunity),
        selectedCompany || undefined,
        repeatEnabled ? repeat : 0,
        repeatEnabled ? endRepeat : 0,
        requests
      );
      await createShopRequests(payload).then(() => {
        setFilters({ ...filters, page: 1 });
        queryClient.invalidateQueries(['shopRequests', filters, ordering]);
        queryClient.invalidateQueries(['shop-requests-tabs-metrics', filters]);
        onCancel();
        showMessage('success', 'Shop requests created');
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      showMessage('error', 'Trouble creating shop requests.');
      console.error('Trouble creating shop requests.', e);
    }
  };

  const selectCommunity = useCallback(
    (community: any) => {
      setShopRequests([
        ...shopRequests,
        {
          communityId: community.id,
          communityName: community.apartmentname,
          independentLiving: true,
          assistedLiving: true,
          memoryCare: true,
          adminNote: null,
          dueDate: getDateString(dueDate),
          repeat,
          shopperId,
        },
      ]);
    },
    [setShopRequests, shopRequests]
  );

  const deleteCommunity = useCallback(
    (communityId: number) => {
      const filteredShopRequests = shopRequests.filter((r) => r.communityId !== communityId);
      setShopRequests(filteredShopRequests);
    },
    [shopRequests, setShopRequests]
  );

  const replaceCommunity = useCallback(
    (community: any, newCommunity: any) => {
      const shopRequestIndex = shopRequests.findIndex((r) => r.communityId === community.id);
      const updatedShopRequests = [...shopRequests];
      updatedShopRequests[shopRequestIndex] = {
        ...updatedShopRequests[shopRequestIndex],
        communityId: newCommunity.id,
        communityName: newCommunity.name,
      };
      setShopRequests(updatedShopRequests);
    },
    [shopRequests, setShopRequests]
  );

  return (
    <StyledModal
      open={open}
      onCancel={onCancel}
      onOk={handleConfirm}
      okButtonProps={{ disabled: isOkButtonDisabled }}
      title="Create Mystery Shop Request"
      okText="Create"
      width="fit-content"
    >
      <ShopRequestModalContext.Provider
        value={{
          requestFrom,
          remainingCommunities,
          dueDate,
          repeat,
          name,
          shopRequests,
          specificDueDate,
          staffUsers,
          shopperId,
          selectedCompany,
          shopForBaseCommunity,
          selectedBaseCommunity,
          companyCommunities,
          companyCompetitors,
          communityList,
          baseCommunityIL,
          baseCommunityAL,
          baseCommunityMC,
          repeatEnabled,
          endRepeat,
          setEndRepeat,
          setRepeatEnabled,
          setBaseCommunityIL,
          setBaseCommunityAL,
          setBaseCommunityMC,
          setSelectedBaseCommunity,
          setShopForBaseCommunity,
          setSelectedCompany,
          setShopperId,
          setName,
          setRequestFrom,
          selectCommunity,
          setDueDate,
          setRepeat,
          setShopRequests,
          deleteCommunity,
          replaceCommunity,
          setSpecificDueDate,
        }}
      >
        <CreateShopRequestModalContent />
      </ShopRequestModalContext.Provider>
    </StyledModal>
  );
};

export default CreateShopRequestModal;
