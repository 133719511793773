import React from 'react';

import styled from 'styled-components';

import { CARE_LEVELS_STATS_TYPE, CARE_LEVELS_STATS_TYPE_LABELS } from 'components/View/constants';

import { useWidgetContext } from './WidgetContext';
import Dropdown from '../../../components/lib/Dropdown';
import DownCaretIcon from '../../Icons/DownCaretIcon';
import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPE_LABEL, WIDGET_FILTER_TYPES, WIDGET_FILTERS } from '../constants';

const StyledDropdownMenu = styled.div`
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 1px solid var(--line-gray);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 155px;
    max-height: 160px;
    pointer-events: none;
    overflow-y: auto;

    .ant-dropdown-menu-item {
      font-size: 12px;
      pointer-events: none;
    }
  }
`;

const Label = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 2px;
`;

interface Props {
  widgetType: number;
  filterType: number;
  selection: number[];
  options?: any[];
}

const WidgetFiltersInfoLabel = ({ widgetType, filterType, selection, options }: Props) => {
  const { filters } = useWidgetContext();

  if (!WIDGET_FILTERS[widgetType]?.includes(filterType)) return null;

  const filterOptions = options ?? WIDGET_FILTER_OPTIONS[filterType];

  let countLabel = <Label>All {WIDGET_FILTER_TYPE_LABEL[filterType][1]}</Label>;

  if (selection.length === 1) {
    const selectedItem = filterOptions.find((item) => item.id === selection[0]);
    countLabel = <Label>{selectedItem?.name}</Label>;
  } else if (selection.length > 1 && selection.length < filterOptions.length) {
    countLabel = (
      <Label>
        {selection.length} {WIDGET_FILTER_TYPE_LABEL[filterType][1]}
      </Label>
    );
  }

  const items = filterOptions
    .filter((option: any) => selection.includes(option.id))
    .map((item: any) => ({
      key: item.id,
      label: item.name,
    }));

  return (
    <>
      <Dropdown
        menu={{ items }}
        dropdownRender={(menu) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
        disabled={selection.length < 2}
        placement={'bottomRight'}
      >
        <Label style={{ cursor: selection.length > 1 ? 'pointer' : 'default' }}>
          {countLabel}
          {selection.length > 1 && <DownCaretIcon />}
          {filterType == WIDGET_FILTER_TYPES.CARE_LEVEL &&
            `(${
              CARE_LEVELS_STATS_TYPE_LABELS[filters.careLevelsStatsType] ||
              CARE_LEVELS_STATS_TYPE_LABELS[CARE_LEVELS_STATS_TYPE.AVG]
            })`}
        </Label>
      </Dropdown>
      <span>·</span>
    </>
  );
};

export default WidgetFiltersInfoLabel;
