import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  reviews: number[];
};

const CMASummaryTableDataReview = ({ reviews }: Props) => {
  if (!reviews?.length) {
    return null;
  }

  const [average, total] = reviews;
  return (
    <div>
      <Title>{average}</Title>
      <Subtitle>{total}</Subtitle>
    </div>
  );
};

export default CMASummaryTableDataReview;
