import React, { useState } from 'react';

type Props = {
  initialMinimum: number;
  initialMaximum: number;
};

const useArrayBoundary = ({ initialMinimum, initialMaximum }: Props) => {
  const [minimum, setMinimum] = useState(initialMinimum);
  const [maximum, setMaximum] = useState(initialMaximum);

  const moveRight = () => {
    setMinimum((prevMinimum) => prevMinimum + 1);
    setMaximum((prevMaximum) => prevMaximum + 1);
  };
  const moveLeft = () => {
    setMinimum((prevMinimum) => prevMinimum - 1);
    setMaximum((prevMaximum) => prevMaximum - 1);
  };

  return { minimum, maximum, moveRight, moveLeft };
};

export default useArrayBoundary;
