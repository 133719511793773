import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  livingInfo?: string[];
};

const CMASummaryTableDataLivingInfo = ({ livingInfo }: Props) => {
  const rent = livingInfo ? livingInfo[0] : null;
  const squareFootage = livingInfo ? livingInfo[1] : null;

  return (
    <div>
      {rent ? (
        <>
          {rent && <Title>${rent.toLocaleString()}</Title>}
          {squareFootage && <Subtitle>{squareFootage.toLocaleString()} sq ft</Subtitle>}
        </>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

export default CMASummaryTableDataLivingInfo;
